import React from 'react';
import {Alert} from 'react-bootstrap'
import {dsnCN} from "../../../hooks/helper";

function MenuContent({className}) {
    const socialData = [
        {link: "https://www.facebook.com/sn.fouadassadi/", name: "Facebook."},
        {link: "https://www.instagram.com/website.design/", name: "Instagram."},
        {link: "https://www.linkedin.com/in/fouad-assadi-91750454/", name: "Linkedin."},
    ];
    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            {/* <Alert key={'success'} variant={'success'}>test</Alert> */}
            <div className="nav__info">
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Fouad Assadi</h5>
                    <p>
                        Toronto area <br/> Ontorio, Canada
                    </p>

                </div>
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Contact</h5>
                    <p className="links over-hidden mb-1">
                        <a className="link-hover" href="tel:+14389200640" data-hover-text="+1 (438) 920 0640">
                            +1 (438) 920 0640
                        </a>
                    </p>
                    <p className="links over-hidden">
                        <a className="link-hover" href="mailto:support@varexe.com"
                           data-hover-text="support@varexe.com">support@varexe.com</a>
                    </p>
                </div>
            </div>
            <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Follow us</h5>
                    <ul>
                        {socialData.map((item, index) =>
                            <li key={index}>
                                <a href={item.link} target="_blank" rel="nofollow noopener noreferrer">{item.name}</a>
                            </li>)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuContent;